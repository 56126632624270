import { Logo, LogoSizes } from "@afterpaytouch/core";
import React, { useMemo } from "react";

import { useCurrentLocale } from "@/context/LanguageContext";

import { useIsArcade } from "@/hooks/useIsArcade";
import useViewport from "@/hooks/useViewport";

import { Locale } from "../../types/locale";
import { BaseLogo } from "./legacy-icon/base-logo";

export const getLogo = (
    locale: Locale,
    size: LogoSizes = "XLarge",
    isArcade = false
): React.ReactElement<typeof Logo> => {
    switch (locale) {
        case "en-GB":
        case "fr-FR":
        case "es-ES":
        case "it-IT":
            return <Logo.Clearpay size={size} />;
        default:
            return isArcade ? <Logo.Afterpay size={size} /> : <BaseLogo size={size} />;
    }
};

export const ApLogo: React.FC = () => {
    const locale = useCurrentLocale();
    const { isDesktop, hasWindow } = useViewport();
    const isArcade = useIsArcade();

    const size = useMemo(() => (isDesktop ? "XLarge" : "Large"), [isDesktop]);

    return hasWindow ? getLogo(locale, size, isArcade) : null;
};
