export const LOGO_NAMES = {
    AFTERPAY: "Afterpay",
    CLEARPAY: "Clearpay",
} as const;

export const CASH_LOGO_NAMES = {
    AFTERPAY: "AfterpayByCashapp",
    CLEARPAY: "Clearpay",
} as const;

export const CASH_LOGO_NAME_MAP = {
    [LOGO_NAMES.AFTERPAY]: CASH_LOGO_NAMES.AFTERPAY,
    [LOGO_NAMES.CLEARPAY]: CASH_LOGO_NAMES.CLEARPAY,
};

export const COLORS = {
    DEFAULT: "Black",
} as const;

export const COLORS_MAP = {
    [COLORS.DEFAULT]: "#000",
};

export const VIEWBOX_MAP: Record<string, string> = {
    afterpay: "0 0 108 20",
} as const;

export const SIZES = {
    SMALL: "Small",
    MEDIUM: "Medium",
    LARGE: "Large",
    XLARGE: "XLarge",
} as const;

export const ROLES = {
    IMAGE: "img",
} as const;
