import clsx from "clsx";
import React, { FunctionComponent, ReactNode } from "react";

import { LogoNames } from "./logo";
import { Afterpay as AfterpayLogo } from "./logos/afterpay";
import { COLORS, COLORS_MAP, ROLES, SIZES, VIEWBOX_MAP } from "./logos/constants";
import styles from "./style.module.scss";

type ColorsType = typeof COLORS;
type ColorsKeys = keyof ColorsType;
export type LogoColors = ColorsType[ColorsKeys];

type SizesType = typeof SIZES;
type SizesKeys = keyof SizesType;
export type LogoSizes = SizesType[SizesKeys];

export interface LogoProps {
    name?: LogoNames;
    color?: LogoColors;
    size?: LogoSizes;
    title?: string;
    description?: string;
}

export interface SvgPropsLogo {
    stroke?: string;
    fill?: string;
}

export type LogoVariantComponent = FunctionComponent<LogoProps>;
type CSSClasses = keyof typeof styles;

const Logo: LogoVariantComponent = ({
    name,
    color,
    size = SIZES.MEDIUM,
    title,
    description,
    ...otherProps
}) => {
    const containerClassnames = clsx(styles.container, styles[`logo${size}`] as CSSClasses);

    const ariaHidden = typeof title === "undefined" && typeof description === "undefined";

    const renderTitle = (): ReactNode => {
        if (typeof title === "undefined") {
            return null;
        }
        return <title>{title}</title>;
    };

    const renderDescription = (): ReactNode => {
        if (typeof description === "undefined") {
            return null;
        }
        return <desc>{description}</desc>;
    };

    const renderLogo = (): ReactNode => {
        const svgColor = COLORS_MAP[color as LogoColors];
        return <AfterpayLogo stroke={svgColor} fill={svgColor} />;
    };

    return (
        <span className={containerClassnames} data-name={name} {...otherProps}>
            <svg
                role={ROLES.IMAGE}
                aria-hidden={ariaHidden}
                width="100%"
                height="100%"
                viewBox={VIEWBOX_MAP.afterpay}
            >
                {renderTitle()}
                {renderDescription()}
                {renderLogo()}
            </svg>
        </span>
    );
};

export const BaseLogo = Logo;
